import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import PostCard from './PostCard';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getToken, isPaid } from '../api/apiUser';
import '../css/dynamic.css';

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [decks, setDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);

  useEffect( () => {
    const fetchDecks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_decks`,{
          headers: {
            Authorization: 'Bearer ' + getToken()
          }
        });
        console.log(response);
        setDecks(response.data.decks);
      } catch (error) {
        console.log('error fetching decks: ', error);
      }
    };
    void fetchDecks();
  }, []);

  const syncAnki = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sync`, {
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      });
  
      if (response.data.error) {
        console.log(`Failed to sync: ${response.data.error}`);
        Swal.fire({
          icon: 'error',
          title: 'Sync Failed',
          text: `Failed to sync: ${response.data.error}`,
        });
      } else {
        console.log("Sync successful");
        Swal.fire({
          icon: 'success',
          title: 'Sync Successful',
          text: 'Your Anki data has been successfully synced!',
        });
      }
    } catch (error) {
      console.error("Failed to sync: " + error.message);
      Swal.fire({
        icon: 'error',
        title: 'Sync Failed',
        text: `Failed to sync: ${error.message}`,
      });
    }
  };
  
  return (
    <div className="main-div">
      <PostCard selectedDeck={selectedDeck} />
      
      <div style={{paddingTop: "50px"}}>
        <Autocomplete
          disablePortal
          options={decks}
          getOptionLabel={(option) => option}
          renderInput={(params) => <TextField {...params} label="Select Deck" />}
          value={selectedDeck}
          onChange={(event, newValue) => setSelectedDeck(newValue)}
          sx={{ mb: 3, width: { xs: '100%', sm: 190 } }}
        />
      </div>

      <Button
        onClick={() => syncAnki()}
        disabled={loading}
        style={{ width: '100%', maxWidth: '200px' }}
      >
        {loading ? 'Syncing...' : 'Sync'}
      </Button>
    </div>
  );

  
  /*
  return (
    <Container className="mt-3">
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <div
            className="p-4 pb-3 bg-body-tertiary hover-effect"
            style={{ borderRadius: '10px', minHeight: '100vh' }}
          >
            {getToken() ? (
              <>
                {localStorage.getItem('isPaid') === 'true' ? (
                  <>
                    <Autocomplete
                      disablePortal
                      options={decks}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} label="Select Deck" />}
                      value={selectedDeck}
                      onChange={(event, newValue) => setSelectedDeck(newValue)}
                      sx={{ mb: 3, width: { xs: '100%', sm: 300 } }}
                    />
                    {selectedDeck && (
                      <>
                        <div
                          className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect"
                          style={{ borderRadius: '10px' }}
                        >
                          <div
                            style={{
                              border: '1px solid black',
                              padding: '16px',
                              borderRadius: '8px',
                            }}
                          >
                            <PostCard selectedDeck={selectedDeck} />
                          </div>
                        </div>
                        <Button
                          onClick={() => syncAnki()}
                          disabled={loading}
                          style={{ width: '100%', maxWidth: '200px' }}
                        >
                          {loading ? 'Syncing...' : 'Sync'}
                        </Button>
                        <MakeDeck />
                      </>
                    )}
                  </>
                ) : (
                  <Pay />
                )}
              </>
            ) : (
              <>Log in to use the app.</>
            )}
            {error && <p className="text-danger mt-3">Error: {error}</p>}
          </div>
        </Col>
      </Row>
    </Container>
  );*/
}
