import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { startNewDroplet, deleteDroplet } from '../api/apiUser';
import axios from 'axios';
import CardComponent from './CardComponent';
import Swal from 'sweetalert2';
import { getToken } from '../api/apiUser';
import NumericInput from 'react-numeric-input';
import '../css/CardNumberInput.css'

export default function PostCard(selectedDeck) {
  const [error, setError] = useState(null);
  const [word, setWord] = useState(null);
  const [translateFrom, setTranslateFrom] = useState('');
  const [translateTo, setTranslateTo] = useState('');
  const [cards, setCards] = useState([]);
  const [generateImage, setGenerateImage] = useState(false); // New state for Generate Image
  const [cardIndex, setCardIndex] = useState(0);

  const commonLanguages = [
    { code: 'English', label: 'English' },
    { code: 'Spanish', label: 'Spanish' },
    { code: 'French', label: 'French' },
    { code: 'German', label: 'German' },
    { code: 'Italian', label: 'Italian' },
    { code: 'Chinese', label: 'Chinese' },
    { code: 'Japanese', label: 'Japanese' },
    { code: 'Russian', label: 'Russian' },
    { code: 'Arabic', label: 'Arabic' }
  ];

  const updateCards = (obj, index) => {
    setCards((prevCards) => {
      const updatedCards = [...prevCards];
  
      if (index < 0) {
        updatedCards.push(obj);
        return updatedCards;
      }
  
      if (index >= 0 && index < updatedCards.length) {
        updatedCards[index] = {
          ...updatedCards[index], // Preserve existing properties
          ...obj, // Overwrite with new properties
        };
        return updatedCards;
      }
  
      console.warn(`Index ${index} is out of bounds. No changes made.`);
      return updatedCards;
    });
  
    // Return the operated index
    return index < 0 ? cards.length : index;
  };
  
  const cullInvisible = () => {
    setCards((prevCards) => {
      const visibleCards = prevCards.filter(card => card.visible == true);
      return visibleCards;
    });
  };

  const postCard = async () => {
    if (selectedDeck?.selectedDeck?.length == null) {
      Swal.fire({
        icon: 'error',
        title: 'Deck not selected!',
        text: 'Select a deck to upload to.',
      });
      return;
    }
    console.log(translateFrom, translateTo);
    if (translateFrom == '' || translateTo == '') {
      Swal.fire({
        icon: 'error',
        title: 'Language not selected!',
        text: 'Make sure you have selected your languages.',
      });
      return;
    }
    setError(null);
    Swal.fire({
      icon: 'success',
      title: 'Generating card...',
      text: 'AI is making your card...',
    });
    const index = updateCards({
      english_word: word,
      loading: true,
      visible: true,
    }, -1); //make a new card with -1 and get the index
    try {
      const deckName = selectedDeck.selectedDeck;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/post_card`, {
        word,
        translateFrom,
        translateTo,
        deckName,
        generateImage, 
      }, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      console.log('response:', response);

      updateCards({
        deck_name: response.data.data.deck_name,
        english_word: response.data.data.english_word,
        non_english_word: response.data.data.non_english_word,
        english_sentences: response.data.data.english_sentences,
        non_english_sentences: response.data.data.non_english_sentences,
        img_url: response.data.data.img_url,
        original_english_word: response.data.data.english_word,
        original_non_english_word: response.data.data.non_english_word,
        loading: false,
        visible: true,
      }, index); //update the loading card

    } catch (error) {
      setError('Failed: ' + error.message);
      updateCards({
        english_word: `${word}, failed`,
        loading: true,
        visible: true,
      }, index);
    } finally {
      console.log('Success')
    }
  };

  const uploadCard = async (index) => {
    if (selectedDeck?.selectedDeck?.length == null) {
      Swal.fire({
        icon: 'error',
        title: 'Deck not selected!',
        text: 'Select a deck to upload to.',
      });
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_card`, cards[index], {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      Swal.fire({
        icon: 'success',
        title: 'Card Uploaded!',
        text: 'The card was successfully uploaded',
      });
      console.log(response);
      updateCards({visible:false}, index);
      cullInvisible();

      // Log the updated state after the update
      setTimeout(() => {
        console.log(cards,index); // This ensures the log reflects the updated state
      }, 0);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Card Upload Error',
        text: 'The card failed to upload',
      });
      console.log(error);
    }
  };

  const rejectCard = async (index) => {
    console.log('reject');
    Swal.fire({
      icon: 'success',
      title: 'Card Rejected',
      text: 'You rejected the card. Generate another.',
    });
    updateCards({visible:false}, index);
    cullInvisible();

    // Log the updated state after the update
    setTimeout(() => {
      console.log(cards, index); // This ensures the log reflects the updated state
    }, 0);
  };

  const editCard = (newVal, field, index) => {
    if (index < 0 || index >= cards.length) {
      console.error(`Invalid index: ${index}`);
      return;
    }
    const card = cards[index];
    if (!card) {
      console.error(`Card at index ${index} is undefined`);
      return;
    }
    let editedCards = [...cards];
    editedCards[index] = {
      ...card,
      [field]: newVal,
    };
    updateCards(editedCards[index], index);
  };
  
  
  return (
    <div>
      <div>
        <div style = {{display: "flex", flexDirection: "row"}}>
          <Form.Group className="mb-3" controlId="formTranslateFrom">
            <Form.Select
              value={translateFrom}
              onChange={(e) => setTranslateFrom(e.target.value)}
              style={{ width: "150px", justifyContent: 'center', fontSize: "14px" }}
            >
              <option value="">Translate from</option>
              {commonLanguages.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formTranslateTo">
            <Form.Select
              value={translateTo}
              onChange={(e) => setTranslateTo(e.target.value)}
              style={{ width: "150px", justifyContent: 'center', fontSize: "14px" }}
            >
              <option value="">Translate to</option>
              {commonLanguages.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      {/* Input box for the word */}
      <Form.Group className="mb-3" controlId="formWordInput">
          <Form.Control
            type="text"
            placeholder="Enter word"
            value={word}
            onChange={(e) => setWord(e.target.value)}
            style={{ width: "300px", justifyContent: 'center' }}
          />
        </Form.Group>
      </div>    
        <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
          <Button
            onClick={postCard}
            style={{ width: "150px" }}
            disabled={!word || !selectedDeck}
          >
            Post Card
          </Button>
          <Form.Group className="mb-3" controlId="formGenerateImage" style={{ margin: 0 }}>
            <Form.Check
              type="checkbox"
              label="Generate Image"
              checked={generateImage}
              onChange={(e) => setGenerateImage(e.target.checked)}
            />
          </Form.Group>
        </div>

        {error && <p className="text-danger mt-3">Error: {error}</p>}
        <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center', // Center vertically (optional)
              gap: '16px', // Space between cards
            }}
          >
            {cards.map((card, index) => (
              card.visible && index == cardIndex && (
                <div key={index} style={{ flex: '1 0 300px', maxWidth: '300px' }}>
                  <CardComponent
                    card={card}
                    index={index}
                    uploadFunc={uploadCard}
                    rejectFunc={rejectCard}
                    editFunc={editCard}
                  />
                </div>
              )
            ))}
        </div>
        <div style={{paddingTop: "60px"}}>
          <NumericInput
            min={1}
            max={cards.length > 0 ? cards.length : 1}
            value={cardIndex + 1}            // display as 1-indexed
            onChange={(value) => setCardIndex(value - 1)}
            mobile={true}                 // use the custom spinner, not the native input
            className="numeric-input-wrapper"
            inputClassName="numeric-input-field"
            btnUpClassName="numeric-input-btn-up"
            btnDownClassName="numeric-input-btn-down"
          />
        </div>
    </div>

  );
}
