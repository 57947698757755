import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Container, CssBaseline, Avatar, Grid, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { logIn } from '../api/apiUser';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { getToken } from '../api/apiUser';
import MakeDeck from './MakeDeck';

export default function MyAnki() {
    const navigate = useNavigate();
    const [dockerStatus, setDockerStatus] = useState(null);

  useEffect( () => {
    const getDocker = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_docker`,{
          headers: {
            Authorization: 'Bearer ' + getToken()
          }
        });
        console.log(response.data.message);
        setDockerStatus(response.data.message);
      } catch (error) {
        console.log('error fetching decks: ', error);
      }
    };
    void getDocker();
  }, []);

  const rebootDocker = async () => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will stop and restart the Docker container.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, restart it!',
      cancelButtonText: 'Cancel'
    });
  
    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/reboot_docker`, {
          headers: {
            Authorization: 'Bearer ' + getToken()
          }
        });
  
        Swal.fire({
          title: 'Rebooting...',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        });
  
        console.log(response.data.message);
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to reboot Docker.',
          icon: 'error'
        });
        console.error(error);
      }
    }
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center', padding:'200px', flexDirection: 'column', width: '500px'}}>
        <h2>{dockerStatus}</h2>
        <button onClick={rebootDocker}>Reboot Docker</button>
        <p><a href='make_anki'>Sign in to Anki</a></p>
         <MakeDeck />
    </div>
  );
}
